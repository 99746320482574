import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MySignature from "../images/Signature.svg"
import RSL from "../Audio/RSL.mp3"
import TwitchPollsVideo from "../images/TwitchPolls.mp4"
import TwitchPolls from "../images/Twitch_Polls.gif"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"
import MyLogoFixed from "../components/LogoFixed"
import WebCover from "../images/CommunitySeekingCover.jpg"

const CommunitySeekingDraft = ({data}) => (
  <div> 
  <SEO 
  title="The Future of Entertainment is Community Gathering" 
  />

<Helmet>

<meta name="robots" content="noindex" />

<link rel="subresource" href="../Style/EssayPage.css" />

<meta name="twitter:description" content="The most significant advantage of the new media like YouTube and Twitch is the ability to gather people together — Phil Murphy">
</meta>

<meta name="og:description" content="The most significant advantage of the new media like YouTube and Twitch is the ability to gather people together — Phil Murphy">
</meta>

<meta name="description" content="The most significant advantage of the new media like YouTube and Twitch is the ability to gather people together — Phil Murphy">
</meta>

<meta name="image" content="https://phil.how/static/9463a74ef5c27a0045db95ef4379257c/62657/CommunitySeekingWebCover.png">
</meta>

<meta name="twitter:image" content="https://phil.how/static/9463a74ef5c27a0045db95ef4379257c/62657/CommunitySeekingWebCover.png">
</meta>

<meta name="twitter:image:alt" content="The most significant advantage of the new media like YouTube and Twitch is the ability to gather people together — Phil Murphy">
</meta>

<meta name="thumbnail" content="https://phil.how/static/9463a74ef5c27a0045db95ef4379257c/62657/CommunitySeekingWebCover.png">
</meta>

<meta name="og:image" content="https://phil.how/static/9463a74ef5c27a0045db95ef4379257c/62657/CommunitySeekingWebCover.png">
</meta>

</Helmet>



  <div className="EssayPageWrapper">

      <essayheader><MyLogoFixed /></essayheader>

      <essaycontent>


      <p className="EssayTitlePage">Community Seeking</p>


      <div className="P_style">

      <p>As it happens, I was working side by side with famous content creators for the past 7 years. And during my time, especially the last few years, I've always wondered why most of the user-generated content is a one way street.</p>

      <p>The thing is, UGS has great potential in providing something more significant rather than just content. Everything that's build around, like communities, is actually a holy grail of it. And perhaps the first time in our history we can actually interact with the creator and other viewers, even when there are millions of them across the globe.</p>

      <p>You see, both YouTube and Twitch, as the leading platforms, growth with an unbelievable speed. They already changed the way how we consume content on a daily basis. But I've seen substantial problems with the current way it works.</p> 
        
      <p>These platforms, as well as most famous creators, don't really try to push forward something that these industries are built on. They don't try to take advantage of what this industry is doing the best — providing the ability to gather.</p>

      <p>After all, content creators just scratch the surface of what can be achieved. And I'm happy to start a series of various essays exploring the ways to unlock new horizons.</p>

          <div className="EssayImagePage">
              <a href="../static/43655904d99348a9c812981da5c952df/68386/CommunitySeekingCover.jpg" target="_blank" rel="noopener">
                <Img 
                    fluid={data.CommunitySeekingCover.childImageSharp.fluid}
                    style={{ borderRadius: `10px`}}
                />
              </a>
          </div>

          <div className="IllustrationBy">Illustration by <a href="https://twitter.com/favorite__lie" target="_blank" rel="noopener">@Favorite-Lie</a></div>



          <div className="Contentnderline"></div>

          <p>First things first, I was interested in learning more about digital communities themselves. They were always around, but I tried to find a way to explain their significance.</p>

          <TitleUnderlined title="And here's what I've got" color="green" />

          <p>Digital communities are great from many different perspectives.</p>

          <p>For some, it's been their main communication channel since we all started living online. For others, it's the best way to have fun during tough times. For anyone else, it's just a great way to be heard.</p>

          <p>The best part though is the community and its events could happen anywhere and anytime. Let's say: Twitch chat, Discord room, YouTube comments, Twitter feed, Reddit posts, Gaming Lobbies, etc.;</p> 
            
          <p>It can occur everywhere as long as viewers can feel they're all in this together; thus they can work individually and collectively. Furthermore, it makes the audience feel that it's bigger than themselves.</p>

          

          <p>So all this, in addition to the bit of personal research, put into perspective for me how <span className="TextHighlight">communities rapidly get to the point of being the main and most essential "differentiator" of the gaming, content creation, and livestreaming industries from any other.</span></p>

       

          <p>Thus, consistent contribution and participation in community development are highly critical and rewarding.</p>


          <TitleUnderlined title="Turns out… community building is challenging!" color="red" />

          <p>There are some genuinely good examples of content creators who built really strong and tight-knit communities, like <a href="https://twitter.com/AdmiralBahroo" target="_blank" rel="noopener">Admiral Bahroo</a>, but there are also a lot of bad ones.</p>

          <p>Take large and medium livestreamers for example. You may have noticed a difference in the content flow on different broadcasts. Sometimes the emphasis is on personality, let’s say, Pokimane. Sometimes, is on the skill, e.g. Ninja. Other times, the focus is on a unique format. Thing is, for each type, there are different environments and conditions in which a community gathering can take place.</p>

          <p>But still, this is too arbitrary and something that many creators and platforms, turn a blind eye to (even if they say the opposite). But they shouldn’t.</p>

          <TitleUnderlined title="What should a great community have?" color="green" />

          <p>Ultimately we could try to look at the fundamental things communities are built on. One of them is the desire to unite with people who have similar passions and desires in order to be surrounded by someone who can understand your intentions and urges.</p>

          <p>In the digital age, this desire is only gaining momentum.</p> 
          <p>Because it becomes difficult to find a place where you will be welcomed and where you could speak up. — So the best thing that modern digital communities can provide is a place where your voice and your actions matter.</p>

          <p>Moreover, a <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3951248/" target="_blank" rel="noopener">study</a> conducted at UCSD and Yale University, supports their theory that our emotions can be influenced and transmitted through social networks. Thus, by watching a video, and especially a live broadcast, viewers may start to experience the same emotions as the creator at the moment.</p>

          <p><i><span className="TextHighlight">I.e. Streamer’s happy emotions can cause happy emotions in the viewer, even if the viewer was upset prior.</span></i></p>


          <TitleUnderlined title="So how well has this been done so far?" color="red" />

          <p>The majority of content creators have already started recognizing communities as the main component of their personal brands. Furthermore, the term community itself is beginning to be quite "commonplace" these days.</p>

          <p>But as far as communities becoming more essential for brands and viewers. They don't really reflect anything besides that.</p>

          <p>The thing is, many creators still don't take full advantage of the unique opportunities offered by UGC, user generated content, areas and platforms. Everything that creators try to do is still at the lowest possible threshold and mostly just a copycat.</p>
            
          <p>Another issue here is that creators try to ignore it, while the audience, on the contrary, is always happy with new ways of interacting.</p>

          <p>Moreover, content creation and livestreaming, in particular, still lack a decent level of production, attention to small detail, and lifelong validity. Everything created thus far will hardly become a piece that people will rewatch or replay over and over in the future.</p>

          <p>To put it simply, UGCs just sway from side to side by reinforcing existing ideas and not trying to invent new ones.</p>

          <p>And in an industry so young, it's crucial to find a way to break that loop.</p> 
            
          <p>So here are the most promising keys I found during my research:</p>



          <TitleUnderlined title="1. Mindset & Trends" color="yellow" />

          <p>The pattern is simple. If something happens, it only happens because someone else is doing the same thing. It just doesn't make sense for creators to try to find new ways to interact with viewers or make production changes they've never even thought of.</p> 
          
          <p>And trying to find a possible reason, my primal assumption was peer pressure. The thing is, because other creators do everything the same and feel pretty comfortable with the way things are, it might mean you can too.</p>

          <p>But the real problem here is that the industry simply doesn’t encourage attempts, so all events remain temporary, and "by the occasion" that comes and goes without a trace.</p> 
            
          <p>And someone might object and say that this is happening because the audience doesn't like it or they don't want to see these changes either? No, most viewers want to. But they want it done seamlessly and qualitatively, which is still pretty challenging thing to do.</p>

          <p>The bright side, though, is most creators share information with each other very quickly. And this makes the content creation industry one of the few areas where the viral effect works very effectively.</p> 
            
          <p>As if you light a match once, it will become a fire in seconds.</p>

          <div className="Contentnderline"></div>

          <TitleUnderlined title="2. Interactivity boom" color="yellow" />

          <p>One of the most promising discoveries in the past few years has been interactivity.  The approach when viewers and creators can interact with each other and/or content by using specially designed places within different platforms.</p> 
          
          <p>A significant part of which, by the way, directly intersects with the community and encourages members for their active participation.</p>

          <div className="EssayAddContentPage">Here are a few case studies —</div>

          <TitleUnderlined title="Polls" color="green" />
          <p>One of the simplest and most well-established examples of interactivity in recent years has been polls. The creator allows viewers to choose one of several options, and the audience can decide the outcome.</p>

          <div className="EssaySmallImagePage">
            <a href="../static/1598c954d036f026eec808309c28ff70/6c8f1/TwitchTVPolls.jpg" target="_blank" rel="noopener">
              <Img 
                  fluid={data.TwitchTVPolls.childImageSharp.fluid}
                  style={{ borderRadius: `10px`}}
              />
            </a>
          </div>



          <br />


          <TitleUnderlined title="Immersive Raffles" color="green" />
            
          <p>One of the reasons why a lot of people truly ready to spend a fat stack on subscriptions and sub gifts is because it feels good to be mentioned in front of the crowd, even for a short period.</p>
            
          <p>And yet, everyone who contributes doesn't get much in return.</p> 
            
          <p>And even those who say they don't really need anything, in fact, would like to have something more significant rather than just the mention of their name.</p>
          
          <p>And immersive interactive raffles are probably one of the best examples of how in addition to mentioning your name, you can also get a chance to become the owner of an actual little piece of the show.</p>

          <p>For instance, anyone who subscribed or gave subs away could take part in the raffle where the winner can name their own just newly caught Pokemon that would be staying with the creator and viewers throughout the entire show.</p>

          <div className="EmbedVideo">
                  <iframe src="https://www.youtube.com/embed/VEfCsneNS50" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
          </div>


          <TitleUnderlined title="Full-Fledged Live Shows" color="green" />

        <p>The greatest potential for interactivity can be revealed in full-fledged live shows that are specifically created around this technology.</p>

        <div className="EssayAddContentPage">For example,</div>
        <p><b>Criken’s Colosseum</b> — a live show where users can interact with the in-game environment through specially designed UI system similar to a MMO game. And not where you have dozens of people on the server, but where you have thousands of people in control of the whole game world instead.</p>

        <div className="EmbedVideo">
                  <iframe src="https://www.youtube.com/embed/S3bU5UwRBM0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
          </div>

        <p>Important clarification here is that controlling must be <b>consequential</b>. It's not enough just to give it up to viewers' hands like some other shows and streamers do.</p>

        <p>For instance, choice balance.</p> 
          
        <p>When I played different interactive shows or had the opportunity to vote, it was always based on pure numbers. But pure numbers don't provide an opportunity to measure the significance of the choice. Desire can easily be confused with incorrect balancing. Something that sounds funnier can surpass something that makes more sense and so on.</p> 
        
        <p>TL;DR — <b>Creators must build a foundation that will make control meaningful.</b></p>

      

        <div className="Contentnderline"></div>

        <p>To get a deeper sense of why it works so well, we can try to understand what emotions viewers experience when consuming interactive content and what drives their desires.</p>

        <p>Last year I came across an interview with the CEO of Superhuman, a fast email client, in which he used the emotional wheel from Junto Institute to explain why users like to use his app. I was struck by this example and decided to apply it to my research too:</p>

        <div className="EssayImagePage">
        <a href="../static/1e739efb079949415041584d23155851/8d364/EmotionWheel.png" target="_blank" rel="noopener">
          <Img 
              fluid={data.EmotionWheel.childImageSharp.fluid}
          />
        </a>
      </div>

        <p>In most cases, viewers experienced zealousness and enthusiasm that resulted in excitement. At other times, they were also amused and delighted.</p>

        <p>The most prominent thing that would explain why interactivity is a holy grail here is <b>zealousness impact</b>. This makes the audience “unable” to stop watching the broadcast or video, because of the fear of missing something important and not being as informed as others.</p> 
          
        <p>This worked perfectly with Game of Thrones last year — many didn't want to miss the “hype train” so they were going to extreme measures to watch new episodes right after they were released, and not a minute later (me too).</p>

        <p>The only exception is that Game of Thrones already had a huge viewership and hype behind it, but interactive content allows you to do the same, and even with a smaller audience (this doesn't depend on the viewership size and from my experience it worked even on small broadcasts).</p> 
          
        <p>So this is definitely something worth the attention and time when creating content.</p>

        <p><span className="TextHighlight">Interactivity also increases the time when viewers are most active and directly affects retention</span> — For example, here’s an interesting note from Frodohart, one of the active Twitch viewers and the member of my study cohort:</p>

        <div className="QuoteBlockPurple">
          <div className="QuoteContent"><span className="QuoteHighlight">“I find interacting with the livestream very fun and appealing, it especially keeps me watching throughout the entire show. Normal streams I’ll watch a good portion leave and come back after a break, but with the interactive shows, I find myself staying for the entire stream because of my investment of points, voting, etc.”</span></div>
        </div>

        <TitleUnderlined title="Is there a downside?" color="red" />
        <p>Interactivity, in its essence, implies that viewers have to be active. But some people use the content as a form of escapism, and they might object to such dynamic activity. Or maybe even refuse to be a part of it. Completely ignoring and becoming less invested.</p>

        <p>But that's not a reason why interactivity exists in the first place. The primal goal is to create more immersed super fans, who will feel substantially more connected to the creator. Here's the thing. Interactivity helps to focus on people who really care and are genuinely interested in being a part of the show.</p>

        <p>And by providing more interactive possibilities, thus creating more immersed super fans, I believe it can help increase the sense of belonging across the digital entertainment ecosystem, as well as will bring new business opportunities for creators.</p>

        <div className="Contentnderline"></div>

        <TitleUnderlined title="3. New quality standard" color="yellow" />

        <p>Another key element, besides interactivity, is overall production quality.</p>

        <p>You see, any shift always started with a technical breakthrough. Think of the advanced facial animation in Half-Life 2, for example. Or the incredible UX design of the iPhone back in 2007 that allowed touch screen technology to become the new “normal”.</p>

        <p>In other words, the majority of revolutions started with new quality standards that no one has ever seen before or weren’t available to everyone. And that's exactly what is missing in livestreaming area right now.</p> 
            
      <p>Thing is, most livestreams just look sketchy compared to other video-first mediums, like Netflix. Ranging from using weird fonts, alignment and colors down to having video or audio troubles.</p>
          
        <p>The only exceptions are eSports tournaments with huge production budgets and some famous streamers, like Ninja. The rest of the broadcasts look outdated and too cookie-cutter. And the point is that even if you focus on personality and IRL streams, the visual and technical component of interaction with the audience still needs to be more well-planned and thought-out. </p>
          
        <p><span className="TextHighlight">Otherwise, the audience feels the <b>"rawness"</b>, and it's an actual big deal. — It might not affect short-term viewership, but it’s blocking the potential personal, and therefore industry’s growth as a whole <i>(since it’s not enough just to have one "pretty" channel — it must be a trend)</i>.</span></p>

     



        <div className="Contentnderline"></div>

        <p className="EssaySecondHeaderTypeRed"><span className="SecondHeaderFont">What other challenges still exist?</span></p>

        <TitleUnderlined title="New member’s onboarding" color="orange" />
        <p>If your community and content have been around for a while, it can be difficult for new members to adapt and understand what your community is all about and what value they can get or contribute.</p>

        <p>An excellent example of how some communities found a way to solve this problem is by asking all new members to publish a little introduction and having a single place where they can learn more about the values.</p>

        <p>For instance, here's an example of an introduction page when you join the <a href="https://joinsail.co/" target="_blank" rel="noopener">Sail</a> community, a place for men to talk about mental health.</p>

        <div className="EssayImagePage">
        <a href="../static/41e4206937fe9f30dfc23e3a30db482c/e7b12/SailSlack.png" target="_blank" rel="noopener">
          <Img 
              fluid={data.SailSlack.childImageSharp.fluid}
              style={{ borderRadius: `10px`}}
          />
        </a>
      </div>

        <p>Some examples from the community owners of how to make it better at the very beginning, and it's already much more comfortable navigating through all other parts and know what you can do here.</p>

        <p>The remaining problem is still out there, though. One of the questions that still need to be answered is how to provide enough information to make it crystal clear for people who both are only exploring the community existence and thinking about joining it.</p>


        <TitleUnderlined title="Control delegation" color="orange" />

        <p>A potential issue that can delay the content and industry's development is the time that creators have. The thing is, lack of time comes from difficult and somewhat tough content creation processes and personal matter in between. So it can't be fixed just by creating new tools.</p>

        <p>The only solution is to delegate community management to someone you trust and has an interest in it. I'm betting the first to become Content Creators’ Community Managers will be Moderators — who also can become the main link between viewers and creators <i>(they already are, just not as powerful and standalone as they can be)</i>.</p>

        <p>The only thing they need is to have a bit more advanced set of tools rather than what they have now.</p>



        <TitleUnderlined title="Ways to interact" color="orange" />
        <p>Interactivity, to some extent, will become an integral part of all entertainment communities, so creators and moderators will need platforms and tools that can allow them to create various interactive events with their fans and viewers as efficiently as possible.</p> 
          
        <p>At the moment, the only way to carry out such actions is to use terribly archaic tools or hire engineers/designers for a good amount of money and, most likely, don’t have the ability to customize it afterward.</p>

        <TitleUnderlined title="Creator’s Mental Health Struggle" color="orange" />
        <p>Although I have devoted my research to the subject of what the industry needs to grow, I’d also like to mention a very serious and important problem on the other side — The pressure that creators feel trying to make viewers happy.</p>

        <p>It’s hard — No matter how you feel personally, you must continue to create content to give viewers positive emotions. It's exhausting. Sometimes this happens because of the toxicity of other people. Sometimes because of personal problems you are not ready to share with the world. Sometimes it's a feeling that you can't explain even to yourself.</p>

        <p>This is not seen by the public. Sometimes people close to them don't even know about it. It’s complicated.</p>

        <p>So if you're watching someone and you like the content, let the creator know. Your words have an invisible force, that could make a difference.</p> 
          
        <p>And one day, their content might support you back!</p>

        <div className="Contentnderline"></div>


        <div className="BottomLine">
            <div className="BottomLineTitle">Bottom Line</div>
            <div className="BottomLineContent">Things will not change right away. But as long as creators will keep recognizing the potential in investing their time and resources to build more powerful communities, create more immersive interactive live experiences, and will level up their production quality. We will see the thrive of the entire content creation industry in a new light.</div>
          
        </div>

        <p style={{ textAlign: `center`}}>If you're enjoyed it and interested in the future of entertainment, I would really appreciate your support with sharing my <a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener">thread on Twitter</a> :)</p>
        
        <div className="Contentnderline"></div>
      

       <p style={{ textAlign: `center`}}>If you'd like to discuss mental health, livestreaming or content creation — feel free to reach out to me via <b>nerf@hey.com</b></p>


      </div>


      </essaycontent>







      <essayfooter>
      <div>
        <div className="Signature"><img src={MySignature} width="166" /></div>
      </div>
      </essayfooter>

  </div>

  </div>
)

export default CommunitySeekingDraft

export const query = graphql`
  query {

    EmotionWheel: file(relativePath: { eq: "EmotionWheel.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    CommunitySeekingCover: file(relativePath: { eq: "CommunitySeekingCover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    TwitchTVPolls: file(relativePath: { eq: "TwitchTVPolls.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    SailSlack: file(relativePath: { eq: "SailSlack.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    CommunitySeekingWebCover: file(relativePath: { eq: "CommunitySeekingWebCover.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`